import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useLocation } from "@reach/router";
import Head from "../../components/Head/Head";
import Header from "../../components/Header/Header";
import FreeFooter from "../../components/common/Footer/FreeFooter/FreeFooter";
import EstimateSection from "../../components/sections/YourEstimate/YourEstimate";
import Videos from "../../components/sections/Videos/Videos";
import MuchMore from "../../components/sections/MuchMore/MuchMore";
import Questions from "../../components/sections/Questions/Questions";
import PRICING_ACTIONS from "../../redux/actions/Pricing";
import packPricingObject from "../../functions/pricing/packPricingObject";
import ForumApi from "../../procedures/forum";

const Intro = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [modules, setModules] = useState([]);
  const Pricing = useSelector((state) => {
    return state.Pricing;
  });
  const dispatch = useDispatch();
  const [content, setContent] = useState([]);
  const [fid, setFid] = useState("");
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [title, setTitle] = useState("");

  const isBrowser = typeof window !== "undefined";
  const server = isBrowser ? window?.location?.origin : null;
  const {
    config: { estimate },
  } = packPricingObject({ ...Pricing, server });
  const setBudget = async () => {
    setLoading(true);
    if (estimate) {
      const formatAmount = estimate.split("$")[1].split(".")[0].split(",");
      const action = PRICING_ACTIONS.setEstimateBudget(formatAmount.join(""));
      dispatch(action);
    }
    setLoading(false);
  };
  const getForumData = async (page) => {
    try {
      setLoadingMedia(true);
      const res = await ForumApi.getForumData(page);
      setContent(res?.pageMedia?.assets);
      setDescription(res?.pageMedia?.description);
      setTitle(res?.pageMedia?.title);
      setFid(res?.fid);
      setModules(res?.modules);
      setComments(res?.comments);
      setLoadingMedia(false);
      dispatch(PRICING_ACTIONS.setForumId(res?.fid));
    } catch (error) {
      setLoadingMedia(false);
      setContent([]);
    }
  };
  useEffect(() => {
    getForumData("sign-up");
    setBudget();
  }, []);
  useEffect(() => {
    dispatch(PRICING_ACTIONS.setTotalComments(comments?.length));
  }, [comments]);
  return (
    <>
      {loading || loadingMedia ? (
        <div className="page-spinner spinner-gray">
          <Spinner
            animation="border"
            role="status"
            color="white"
            className="spinner"
          />
          <strong className="d-block">Please wait</strong>
        </div>
      ) : (
        <>
          <Head />
          <Header pageId={location.pathname} />
          <div className="content-holder">
            <EstimateSection
              modules={modules}
              showBanner
              setShowSignup={setShowSignup}
              showSignup={showSignup}
              showPricingModal={showPricingModal}
              setShowPricingModal={setShowPricingModal}
              page="intro"
              // showBudget
              showSidePanel
              title={title}
              description={description}
            />
            <Videos
              media={content}
              description={description}
              page="intro"
              setShowSignup={setShowSignup}
            />
            <MuchMore
              page="intro"
              as="Get Price"
              showSignup={showSignup}
              setShowSignup={setShowSignup}
            />
            {fid && (
              <Questions
                page="sign-up"
                pageFid={fid}
                comments={comments}
                setComments={setComments}
                commentsLoading={commentsLoading}
                setCommentsLoading={setCommentsLoading}
              />
            )}
          </div>
          <FreeFooter />
        </>
      )}
    </>
  );
};

export default Intro;
